const actions = {
  getData({ }, queryParams) {

    return new Promise((resolve, reject) => {
      axios.get(`/api/admin/home`, {
        params: queryParams
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
export default {
  namespaced: true,
  actions,
}
