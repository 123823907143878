import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store'
import config from './config'
import vuetify from './plugins/vuetify'
import mixin from "./mixin";
import i18n from '@/language/lang'
import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);
Vue.mixin(mixin);

Vue.config.productionTip = false

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import elementAr from "element-ui/lib/locale/lang/ar";
import elementEn from "element-ui/lib/locale/lang/en";
import locale from 'element-ui/lib/locale'

Vue.config.disableNoTranslationWarning = true;

import notify from 'element-ui/lib/notification'

import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

import './assets/scss/index.scss'


import axios from 'axios';
window.axios = axios.create({
  baseURL: config.hostUrl,
});

window.axios.defaults.headers.lang = config.lang;
if (config.token) {
  window.axios.defaults.headers.common = {
    'Authorization': `Bearer ${config.token}`,
    'language': config.lang
  }
}

if (config.lang == 'ar') {

  Vue.use(ElementUI, { elementAr });
  locale.use(elementAr)
}

else {
  Vue.use(ElementUI, { elementEn });
  locale.use(elementEn)
}

import VueSignature from "vue-signature-pad";
Vue.use(VueSignature);



window.axios.interceptors.response.use(function (res) {
  return res;
}, function (result) {
  if(result && result.response && result.response.data &&  result.response.data.data) {
    notify.error({
      title: i18n.t('Error'),
      message: result.response.data.data,
      duration: 3000,
    });
  }
  else if(result && result.response && result.response.data &&  result.response.data.errors) {
    notify.error({
      title: i18n.t('Error'),
      message: result.response.data.errors,
      duration: 3000,
    });
  }
  else if(result && result.response && result.response.data &&  result.response.data.message) {
    notify.error({
      title: i18n.t('Error'),
      message: result.response.data.message,
      duration: 3000,
    });
  }
  else if (result && result.response && result.response.status == 403) { 
    router.push({name: 'noPermission'})
  }
  else if (result && result.response && result.response.data.includes('Route [login] not defined')) { 
    // server error
    notify.error({
      title: i18n.t('Error'),
      message: 'Token Finished',
      duration: 3000,
    });
    setTimeout(() => {
      mixin.methods.logoutSuccess();
    }, 1000);
  }

  else if (result && result.response && result.response.status == 500) { 
    notify.error({
      title: i18n.t('Error'),
      message: 'Error Happened',
      duration: 3000,
    });
  }
  
  return Promise.reject(result);
})



new Vue({
  router,
  store,
  vuetify,
  i18n,
  mixins: [mixin],
  render: function (h) { return h(App) }
}).$mount('#app')
