import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)


let token = localStorage.getItem('token');

const routes = [
  {
    path: '/admin',
    name: 'admin',
    meta: { requiresAuth: true },
    redirect: { name: 'home' },
    component: () => import('../views/templates/Admin.vue'),
    children: [
      {
        path: 'home',
        name: 'home',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/home/HomePage.vue'),
      },
      {
        path: 'patient',
        name: 'patient',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/patient/index.vue'),
      },
      {
        path: 'patient/details/:id',
        name: 'patientDetails',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/patient/details.vue'),
      },
      {
        path: 'constant',
        name: 'constant',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/constant/index.vue'),
      },
      {
        path: 'constantDetail/:id',
        name: 'constantDetails',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/constant/details.vue'),
      },
      {
        path: 'optometrists',
        name: 'optometrists',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/optometrist/index.vue'),
      },
      {
        path: 'optometrists/addEdit/:id?',
        name: 'optometristsAddEdit',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/optometrist/create.vue'),
      },
      {
        path: 'optometrists/patient_details/:id',
        name: 'optometristsPatientDetails',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/optometrist/patientDetails.vue'),
      },
     
      {
        path: 'user',
        name: 'user',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/user/index.vue'),
      },

      {
        path: 'userAddEdit/:id?',
        name: 'userAddEdit',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/user/addEdit.vue'),
      },

      {
        path: 'user_permission',
        name: 'userPermission',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/user_permission/index.vue'),
      },

      {
        path: 'user_permission/addEdit/:id?',
        name: 'userPermissionAddEdit',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/user_permission/addEdit.vue'),
      },




    ],
  },

  
  {
    path: '/landing',
    name: 'landing',
    redirect: { name: 'login' },
    component: () => import('../views/templates/Landing.vue'),
    children: [
      {
        path: 'login',
        name: 'login',
        meta: { requiresAuth: false },
        component: () => import('../views/pages/login/LoginPage.vue')
      },
    ],
  },


  {
    path: '/reports',
    name: 'reports',
    meta: { requiresAuth: true },
    component: () => import('../views/templates/Reports.vue'),
    children: [
      {
        path: 'patient',
        name: 'patientReport',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/systemConstant/patient/report.vue'),
      },

      {
        path: 'optometrists/report/:id',
        name: 'optometristsReport',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/optometrist/report.vue'),
      },

   
    ],
  },

  



  {
    path: '*',
    redirect: token ? { name: 'home' } : { name: 'login' }
  }

]
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if(to.name == 'login' && token)  next({name: 'home'})
   else if ( ( to.name == 'home' || to.name == 'admin') && !token) next({ name: 'login' })
   else  next();
})
export default router
