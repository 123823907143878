let route = '/api/admin/';
let crud = 'admins';
const actions = {

  getData({ }, queryParams) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}`, {
        params: queryParams
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  listAll({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/list_all`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  

  findData({ }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  
  saveData({ }, data) {

    return new Promise((resolve, reject) => {

      let form = new FormData();
      form.append('name', data.json.name);
      form.append('email', data.json.email);
      form.append('password', data.json.password);
      form.append('confirm_password', data.json.confirm_password);
      if(data.signature) form.append('signature', data.signature);
      if(data.json.signature_text)form.append('signature_text', data.json.signature_text);
      if(data.json.roles)form.append('roles', data.json.roles);

      axios.post(`${route}${crud}`, form)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  
  updateData({ }, data) {

    return new Promise((resolve, reject) => {

      let form = new FormData();
      form.append('name', data.json.name);
      form.append('email', data.json.email);
      if(data.signature) form.append('signature', data.signature);
      if(data.json.signature_text)form.append('signature_text', data.json.signature_text);
      if(data.json.roles)form.append('roles', data.json.roles);

      axios.post(`${route}${crud}/${data.json.id}`, form)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  uploadImage({ }, data) {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      form.append('signature', data.signature);
      axios.post(`${route}${crud}/upload_image/${data.id}`, form)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateStatus({ }, id) {
    return new Promise((resolve, reject) => {
      axios.post(`${route}${crud}/${id}/update_status`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  removeData({ }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${route}${crud}/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

}
export default {
  namespaced: true,
  actions,
}
