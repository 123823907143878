let route = '/api/admin/';
let crud = 'optometrist_report';
const actions = {

  getData({ }, queryParams) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}`, {
        params: queryParams
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getListConstantDetails({ }, id) {
    return new Promise((resolve, reject) => {
      // axios.get(`${route}${crud}/get_list_of_constant_details/${data.id}?name=${data.search}`)
      axios.get(`${route}${crud}/get_list_of_constant_details/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  pdfReport({ }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}pdf/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getAllListsConstantDetails({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/get_all_lists_constant_details`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getListPatients({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}get_list_patients`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  findData({ }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  removeData({ }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${route}${crud}/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },


  saveData({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${route}${crud}`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateData({ }, data) {
    return new Promise((resolve, reject) => {
      axios.patch(`${route}${crud}/${data.id}`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  downloadPdf({ }, id) {

    return new Promise((resolve, reject) => {
      axios({
        url: `${route}${crud}/download_pdf/${id}`,
        method: 'GET',
        responseType: 'blob',

      }).then((response) => {
        var fileURL = window.URL.createObjectURL(response.data);
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'test.pdf');
        document.body.appendChild(fileLink);
        fileLink.click();
        resolve(true);
      }).catch(error => {
        if(error.response.status == 500) {
          reject('حدث خطأ في عملية استخراج البيانات');
        }
        else reject('لا يوجد بيانات في التقرير'); // 422

     })
  })
  },



}
export default {
  namespaced: true,
  actions,
}
