let route = '/api/admin/';
let crud = 'admins';
const actions = {

  listData({ }) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/list_admins`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
export default {
  namespaced: true,
  actions,
}
