var mixin = {
  methods: {
    updateStatusData(statusRequest, id) {
      return this.$store
        .dispatch(statusRequest, id)
        .then((res) => {
          return this.notifySuccess(`UpdateStatus`, res);
        });
    },
    deleteData(deleteRequest, id) {
      return this.$store
        .dispatch(deleteRequest, id)
        .then((res) => {
          return this.notifySuccess(`Delete`, res);
        });
    },
    saveUpdateData(saveRequest, updateRequest, formData) {
        if (formData.id > 0) {
          return this.$store
            .dispatch(updateRequest, formData)
            .then((res) => {
              return this.notifySuccess(`Update`, res);
            });
        } else {
          return this.$store
            .dispatch(saveRequest, formData)
            .then((res) => {
              return this.notifySuccess(`Save`, res);
            });
        }
    },
    getFrontEndData(apiRequest, options, filters) {
      options ? options = Object.assign(options, filters) : options = filters;
      return this.$store
        .dispatch(apiRequest, options)
        .then((res) => {
          return res.data;
        })
    },
    getBackEndData(apiRequest, options) {
      return this.$store
        .dispatch(apiRequest, options)
        .then((res) => {
          return res.data;
        })
    },
    notifyCustomError(title, error) {
      this.$notify.error({
        title: this.$t(title),
        message: this.$t(error),
        duration: 3000,
      });
    },
    notifyError(title, result) {
      this.$notify.error({
        title: this.$t(title),
        message: result.response.data.errors,
        duration: 3000,
      });
    },
    notifySuccess(title, result) {
      this.$notify.success({
        title: this.$t(title),
        message: result.message,
        duration: 3000,
      });
    },
    loginSuccess(result) {
      localStorage.setItem("user", JSON.stringify(result.data.user));
      localStorage.setItem("token", result.data.token);
      window.location.href= '/#/admin/home'
      window.location.reload();
    },
    logoutSuccess() {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      window.location.href= '/#/landing/login'
      window.location.reload();
    }
  }
}
export default mixin;